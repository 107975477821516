<template>
  <div class="ck-editor-wrapper">
    <ckeditor
      v-if="editorConfig"
      :editor="editor"
      :value="html"
      @input="changeHtml"
      :config="editorConfig"
    ></ckeditor>
  </div>
</template>

<script>
import editorFn from "ckeditor";

export default {
  data() {
    return {
      editor: editorFn,
      editorConfig: null,
      html: ""
    };
  },
  props: {
    value: {},
    imglen: {}
  },
  watch: {
    value: {
      handler() {
        this.html = this.value;
      },
      immediate: true
    }
  },
  computed: {},
  beforeMount() {
    let _this = this;
    // let heading = {
    //   options: [
    //     {
    //       model: "paragraph",
    //       title: "正文",
    //       class: "ck-heading_paragraph"
    //     },
    //     {
    //       model: "heading1",
    //       view: "h2",
    //       title: "标题1",
    //       class: "ck-heading_heading1"
    //     },
    //     {
    //       model: "heading2",
    //       view: "h3",
    //       title: "标题2",
    //       class: "ck-heading_heading2"
    //     }
    //   ]
    // };
    let imgMarks = {
      resolveFn() {
        return new Promise(resolve => {
          let caption = "" + (_this.imglen + 1);
          _this.getImgMarkHtml("图片标记", caption).then(html => {
            resolve(html);
          });
        });
      },
      label: "图片标记"
    };
    this.editorConfig = {
      // 使用的是classic编辑器
      toolbar: [
        // "heading",
        // "|",
        "alignment:left",
        "alignment:center",
        "alignment:right",
        // '|',
        // 'imageUpload',
        "|",
        "undo",
        "redo",
        "|",
        "imgMarks"
      ],
      // heading,
      imgMarks // extraPlugins: [CustomUploader]
    };
  },
  methods: {
    changeHtml(h) {
      this.$emit("input", h);
    },
    getImgMarkHtml(size, caption) {
      return new Promise(resolve => {
        let url = "/images/img-mark.jpg";
        resolve(`<figure class="image ck-widget" contenteditable="false">
						<img src="${url}" alt=${size}>
                        <figcaption class="ck-editor__editable ck-editor__nested-editable" 
                        data-placeholder="Enter image caption" contenteditable="true">${caption}</figcaption>
					</figure>`);
      });
    }
  }
};
</script>

<style scoped>
.ck-editor-wrapper {
  min-height: 20em;
  line-height: 25px;
  font-size: 16px;
  vertical-align: middle;
}
::v-deep p {
  padding: 0 0 12px;
}
.ck-editor-wrapper .ck-content {
  height: 100%;
  transition: border 0.2s ease-in-out, background 0.2s ease-in-out,
    box-shadow 0.2s ease-in-out;
  border-radius: 4px !important;
  border: 1px solid #dcd;
  word-break: break-all;
}
.ck-editor-wrapper .ck-content:hover {
  border: 1px solid #47a4f5;
}
</style>