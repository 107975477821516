<template>
  <div>
    <Input
      class="subtitle"
      v-model="hb.contentTitle[currentIndex].content"
      @input="emitChange"
      placeholder="请输入文章标题"
    />
    <Input
      class="subtitle"
      style="margin-left: 2.6em"
      v-if="
        'cnView' === hb.contentType &&
        ('cnViewProse' === hb.category || 'cnViewModernPoetry' === hb.category)
      "
      v-model="hb.attributeList[currentIndex].contentSource"
      @input="emitChange"
      placeholder="选自 / 来源"
    />
    <Input
      class="subtitle"
      style="margin-left: 2.6em"
      v-if="'cnView' === hb.contentType && 'cnViewAphorism' === hb.category"
      v-model="hb.attributeList[currentIndex].author"
      @input="emitChange"
      placeholder="作者"
    />
    <div class="flex">
      <EditorSider
        :activeIndex.sync="currentIndex"
        :len="hb.mdContentList.length"
        :isEmptyFn="isContentEmpty"
        @clickAdd="addContent"
        @clickRemove="removeContent"
      />
      <div class="edit-content" style="width: 1px">
        <CkEditor
          v-for="(cnt, ind) in hb.mdContentList"
          :imglen="hb.picMap[ind].length"
          :key="ind"
          v-show="currentIndex === ind"
          v-model="hb.mdContentList[ind]"
          @input="changeContent(ind)"
          style="height: 42em"
        />
        <div>
          <span
            >汉字数：{{
              (hb.numCns && hb.numCns[currentIndex]) || 0
            }}&nbsp;&nbsp;</span
          >
          <span>英文数：{{ (hb.numEns && hb.numEns[currentIndex]) || 0 }}</span>
        </div>
      </div>
    </div>

    <div class="flex" style="align-items: center" v-if="showImgUploader">
      <div>文章标题图片：</div>
      <ImgSelect
        :imgHolders="[hb.contentTitle[currentIndex]]"
        :config="{ file: 'file', hiddeSelectWhenFull: true }"
        @imgsChange="titleImgChange"
      />
      <div style="width: 280px"></div>
    </div>
    <div v-if="showImgUploader">
      <UploadImg4View
        v-for="(cnt, ind) in hb.mdContentList"
        :key="ind"
        v-show="currentIndex === ind"
        :imgList="hb.picMap[ind]"
        @imgsChange="imgChange"
      />
    </div>
  </div>
</template>

<script>
import CkEditor from "./CkEditor";
import { countHtml } from "../countService";
import hbMixin from "../hbMixin";
import UploadImg4View from "./UploadImg4View";
import * as Service from "./ResovleHtmlWithImgs";

export default {
  props: {
    showImgUploader: {
      default: false,
    },
    hb: {},
  },
  mixins: [hbMixin],
  components: { CkEditor, UploadImg4View },
  data() {
    return {
      contrastDataList: [],
      updatingByImg: false,
      imgList: [],
    };
  },
  methods: {
    isContentEmpty(ind) {
      let div = document.createElement("div");
      div.innerHTML = this.hb.mdContentList[ind];
      let content = div.textContent.replace(/&nbsp;/g, "").trim();
      if (content) {
        console.log("has content:", content);
        return false;
      }
      let img = div.querySelector("img");
      if (img) {
        console.log("has imgs");
        return false;
      }
      return true;
    },
    emitChange() {
      this.$emit("change");
    },
    /**
     * html 内容变了
     */
    changeContent(ind) {
      this.emitChange();
      if (this.updatingByImg) {
        this.updatingByImg = false;
        return;
      }
      let { numCn, numEn } = countHtml(this.hb.mdContentList[ind]);
      this.$set(this.hb.numCns, ind, numCn);
      this.$set(this.hb.numEns, ind, numEn);

      /**
       * html自身改变了，要由html解析更新picMap对应的图片列表
       */
      this.hb.picMap[ind] = Service.resolveHtml(
        this.hb.mdContentList[ind],
        this.hb.picMap[ind]
      );
    },
    titleImgChange() {
      this.emitChange();
      this.$eventBus.$emit("hbChange");
    },
    /**
     * 图片改变了，要同步更新html内容
     */
    imgChange(inds) {
      Service.updateHtmlByImg(
        this.hb.mdContentList[this.currentIndex],
        this.hb.picMap[this.currentIndex],
        inds
      ).then((content) => {
        this.hb.mdContentList[this.currentIndex] = content;

        this.updatingByImg = true;
        this.$forceUpdate();
      });
    },
  },
  mounted() {
    this.currentIndex = 0;
  },
  watch: {
    hb: {
      handler() {
        if (!this.hb) {
          return;
        }
        if (this.hb.contentType === "cnView" && !this.hb.category) {
          // 中文绘本使，二级分类默认普通绘本：
          this.hb.category = "cnView";
        }
        this.$set(this.hb, "numCns", []);
        this.$set(this.hb, "numEns", []);
        for (let i = this.hb.mdContentList.length - 1; i >= 0; i--) {
          this.changeContent(i);
        }
      },
      immediate: true,
    },
  },
};
</script>

<style scoped lang='scss'>
.subtitle {
  width: calc(50% - 3em);
  margin-bottom: 1em;
}
.edit-content {
  flex: 1;
  margin-left: 1em;
}
.edit-content ::v-deep .ck-content {
  overflow-y: scroll;
}
</style>